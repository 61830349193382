import React from 'react';
import AppContacts from '../components/Contacts';
import '../assets/css/Contacts.css';
function ContactsPage(){
  return(
    <div className='Contacts'>
      <AppContacts/>
    </div>
  );
}
 export default ContactsPage;